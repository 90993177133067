import React from "react";
import { EditSelectField, EditTextField, EditDatePickerField, EditCheckbox, EditIntegerNumberField } from '../../components/Share/EditComponents';
import { connect } from 'react-redux';
import { Field, reduxForm } from "redux-form";
import Button from '@mui/material/Button';
import i18next from '../../language/i18n';
import Box from '@mui/material/Box';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Validation from '../../share/validation';
import {
    getPortfolio,
    getPortfolioByPartnerType,
    getPortfolioByGender,
    getPortfolioByBranch,
    getPortfolioByLoanType,
    getPortfolioByInterestRate,
    getPortfolioAtRiskShortened,
    getPortfolioAtRisk,
    getAnalyticalPortfolio,
    getOverdueLoans,
    getOverdueLoansAnalytics,
    getProvFCovCreLos,
    getOutgoingLoans
} from '../../actions/reportAction';
import { getSelectBoxCompanyDetails } from '../../actions/companyDetailsAction';
import { getSelectBoxBranches } from '../../actions/branchAction';
import { getSelectBoxUsers } from '../../actions/userAction';

class PortfolioPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = { currentValue: 1 }
    }
    componentDidMount = () => {
        this.props.getSelectBoxCompanyDetails();
        this.props.getSelectBoxBranches();
        this.props.getSelectBoxUsers();
    }
    loanStatusSelectBox = [{text: "Aktivni krediti", value: 1}, {text: "Otpisani krediti", value: 2}];
    reports = [
        { value: 1, text: 'Portfolio', action: this.props.getPortfolio, isOnDate: true },
        { value: 2, text: 'Portfolio po tipu lica', action: this.props.getPortfolioByPartnerType, isOnDate: true },
        { value: 3, text: 'Portfolio po polovima', action: this.props.getPortfolioByGender, isOnDate: true },
        { value: 4, text: 'Portfolio po kancelarijama', action: this.props.getPortfolioByBranch, isOnDate: true },
        { value: 5, text: 'Portfolio po tipu kredita', action: this.props.getPortfolioByLoanType, isOnDate: true },
        { value: 6, text: 'Portfolio po kamatnoj stopi', action: this.props.getPortfolioByInterestRate, isOnDate: true },
        { value: 7, text: 'Portfolio u riziku - skraćeno', action: this.props.getPortfolioAtRiskShortened, isOnDate: true },
        { value: 12, text: 'Portfolio u riziku', action: this.props.getPortfolioAtRisk, isOnDate: true },
        { value: 13, text: 'Analitički portfolio', action: this.props.getAnalyticalPortfolio, isOnDate: true },
        { value: 8, text: 'Krediti koji kasne', action: this.props.getOverdueLoans, isOnDate: true },
        { value: 9, text: 'Krediti koji kasne analitika', action: this.props.getOverdueLoansAnalytics, isOnDate: true },
        { value: 10, text: 'Rezerve za pokriće kreditnih gubitaka', action: this.props.getProvFCovCreLos, isOnDate: true },
        { value: 11, text: 'Krediti koji izlaze', action: this.props.getOutgoingLoans, isOnDate: false },
    ];
    onSubmit = (formValues) => {
        let reportType = parseInt(formValues.reportType);
        let report = this.reports.find(el => el.value == reportType);

        if (report !== undefined) {
            let data = report.isOnDate ? {
                companyDetailsID: formValues.companyDetailsID,
                branchID: formValues.branchID,
                employeeUserID: formValues.employeeUserID,
                onDate: formValues.onDate,
                loanPortfolioReportStatusID: formValues.loanPortfolioReportStatusID
            } : {
                companyDetailsID: formValues.companyDetailsID,
                branchID: formValues.branchID,
                employeeUserID: formValues.employeeUserID,
                fromDate: formValues.fromDate,
                toDate: formValues.toDate,
                loanPortfolioReportStatusID: formValues.loanPortfolioReportStatusID
            };
            report.action(data);
        }
    }
    render() {
        return (<div>
            <form onSubmit={this.props.handleSubmit(this.onSubmit)}>
                <Field
                    name='reportType'
                    component={this.props.EditSelectField}
                    label='Reports'
                    onChange={
                        (e, child) => {
                            this.setState({ currentValue: parseInt(child) })
                        }
                    }
                    dataSource={this.reports}>
                </Field>
                {
                    this.reports.find(el => el.value == this.state.currentValue).isOnDate ?
                        (<Field
                            name='onDate'
                            component={this.props.EditDatePickerField}
                            label='OnDate'
                        />) :
                        (<>
                            <Field
                                name='fromDate'
                                component={this.props.EditDatePickerField}
                                label='FromDate'
                            />
                            <Field
                                name='toDate'
                                component={this.props.EditDatePickerField}
                                label='ToDate'
                            />
                        </>)
                }
                <Field
                    name='companyDetailsID'
                    component={this.props.EditSelectField}
                    label='CompanyDetails'
                    dataSource={this.props.companyDetailsSelectBox}
                >
                </Field>
                <Field
                    name='branchID'
                    component={this.props.EditSelectField}
                    label='Branch'
                    dataSource={this.props.branchesSelectBox}
                >
                </Field>
                <Field
                    name='employeeUserID'
                    component={this.props.EditSelectField}
                    label='Employee'
                    dataSource={this.props.usersSelectBox}
                >
                </Field>
                <Field
                    name='loanPortfolioReportStatusID'
                    component={this.props.EditSelectField}
                    label='LoanStatus'
                    dataSource={this.loanStatusSelectBox}
                >
                </Field>


                <Box
                    m={1} //margin
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                >
                    <Button color="primary" variant="contained" type="Submit" startIcon={<PictureAsPdfIcon />}>
                        {i18next.t('Report')}
                    </Button>
                </Box>
            </form>
        </div>);
    }
}


const validate = (formValues) => {
    const errors = {};
    if (!formValues.reportType) {
        errors.reportType = 'Polje je obavezno!';
    }
    if (!formValues.onDate) {
        errors.onDate = 'Polje je obavezno!';
    }
    if (!(formValues.loanPortfolioReportStatusID > 0)) {
        errors.onDate = 'Polje je obavezno!';
    }
    return errors;
}

const formWrapper = reduxForm({
    form: 'formPortfolioReport',
    validate: validate,
    destroyOnUnmount: false,
    enableReinitialize: true,
    initialValues: {
        reportType: '1',
        onDate: new Date(),
        fromDate: new Date(),
        toDate: new Date(new Date().setMonth(new Date().getMonth() + 1)),
        loanPortfolioReportStatusID: 1
    }
})(PortfolioPage);
const mapStateToProps = state => {
    return {
        companyDetailsSelectBox: Object.values(state.companyDetailsSelectBox),
        branchesSelectBox: Object.values(state.branchesSelectBox),
        usersSelectBox: Object.values(state.usersSelectBox),
    }
}
export default connect(mapStateToProps, {
    EditSelectField,
    EditTextField,
    EditDatePickerField,
    EditCheckbox,
    EditIntegerNumberField,
    getSelectBoxCompanyDetails,
    getSelectBoxBranches,
    getSelectBoxUsers,

    getPortfolio,
    getPortfolioByPartnerType,
    getPortfolioByGender,
    getPortfolioByBranch,
    getPortfolioByLoanType,
    getPortfolioByInterestRate,
    getPortfolioAtRiskShortened,
    getPortfolioAtRisk,
    getAnalyticalPortfolio,
    getOverdueLoans,
    getOverdueLoansAnalytics,
    getOutgoingLoans,
    getProvFCovCreLos
})(formWrapper);
